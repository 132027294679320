import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import adminReducer from './admin.reducer';
import approvalFormReducer from './approvalForm.reducer';
import authenticationReducer from './authentication.reducer';
import broadcastFormReducer from './broadcastForm.reducer';
import broadcastReducer from './broadcast.reducer';
import commonReducer from './common.reducer';
import contactsFormReducer from './contactForm.reducer';
import dashboardReducer from './dashboard.reducer';
import eventsFormReducer from './eventForm.reducer';
import eventsReducer from './events.reducer';
import registrationReducer from './registration.reducer';
import readingPanelReducer from './reading.panel.reducer';
import sambandhApprovalReducer from './sambandh.reducer';
import readingViewReducer from './reading.reducer';
import userAccessReducer from './userAccess.reducer';
import yogReducer from './yog.reducer';
import followUp from '../views/FollowUp/followUp.ducks';
import followUpDetails from '../views/FollowUpDetails/followUpDetails.ducks';

// v2
import accessForm from '../v2/containers/AccessContainer/accessForm.ducks';
import admin from '../views/Admin/admin.duck';
import contacts from '../views/Contacts/contacts.ducks';

const rootReducer = combineReducers({
  adminData: adminReducer,
  approvalForm: approvalFormReducer,
  auth: authenticationReducer,
  broadcastData: broadcastReducer,
  broadcastForm: broadcastFormReducer,
  commonData: commonReducer,
  contactForm: contactsFormReducer,
  contactsData: contacts,
  dashboardData: dashboardReducer,
  eventsData: eventsReducer,
  eventsForm: eventsFormReducer,
  form,
  readingPanel: readingPanelReducer,
  readingView: readingViewReducer,
  registration: registrationReducer,
  sambandhApproval: sambandhApprovalReducer,
  userAccess: userAccessReducer,
  yogReport: yogReducer,
  followUp,
  followUpDetails,
  accessForm,
  admin,
});

export default rootReducer;
