import { FC, DragEvent } from 'react';
import type { Image } from './ImageUploadGallery.types';

import './styles.css';

type Props = {
  image: Image;
  handleDrop: (e: DragEvent<HTMLElement>) => void;
  handleDrag: (e: DragEvent<HTMLElement>) => void;
  handleRemove: (id: string) => void;
};

const ImageWrapper: FC<Props> = ({ image, handleDrop, handleDrag, handleRemove }) => {
  const url = URL.createObjectURL(image.file);

  return (
    <div
      id={image.id}
      draggable
      onDragOver={(e) => e.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      className="image-wrapper"
    >
      <img
        src={URL.createObjectURL(image.file)}
        alt={`Preview ${image.order}`}
        className="preview-image"
      />
      <button
        onClick={() => {
          handleRemove(image.id);
          URL.revokeObjectURL(url); // Release the reference to avoid memory issues
        }}
      >
        <span>Remove image</span>
      </button>
    </div>
  );
};

export default ImageWrapper;
