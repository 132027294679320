import React, { Fragment } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
import {
  DialogActions,
  DialogContent,
  // DialogContentText,
  DialogTitle,
  Button
} from '@material-ui/core';
import ImageUploadGallery from '../ImageUploadGallery/ImageUploadGallery';
import { closeModal } from '../../actions/common.actions';
import { uploadEventImages } from '../../actions/events.actions';

class EventGalleryModal extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      images: [],
      imageUrls: [],
      uploadSuccess: false,
      uploadMessage: null
    };
  }

  uploadImages = async () => {
    const { images } = this.state;

    const { event } = this.props;

    this.props.uploadEventImages(event._id, event.title, images);
  };

  handleImageSelect = (pictureFiles, pictureDataURLs) => {
    this.setState({
      images: pictureFiles,
      imageUrls: pictureDataURLs
    });
  };

  onImageChange = (images) => {
    this.setState({
      images: images.map((image) => image.file),
    });
  };

  renderEventImages = () => {
    const { event } = this.props;

    const eventImages = [];
    const galleries = event.gallaries;
    if (galleries && galleries.length) {
      galleries.forEach((gallery) => {
        const images = gallery.images;
        if (images && images.length) {
          images.map((image, index) => {
            eventImages.push(<img key={`${image}-${index}`} src={`${image}?w=100`} />);
          });
        }
      });
    }

    return <div className="event-gallery-event-images">{eventImages}</div>;
  };

  render() {
    const { event } = this.props;

    return (
      <Fragment>
        <DialogTitle>Event Gallery for {event.title}</DialogTitle>
        <DialogContent>
          <ImageUploadGallery 
            className='events-image-uploader'
            onChange={this.onImageChange}
            imageExtension={['.jpg', '.jpeg', '.png', '.gif']}
          />
          {
            <Fragment>
              <h3>{event.title} Event Images</h3>
              {this.renderEventImages()}
            </Fragment>
          }
        </DialogContent>
        <DialogActions className={'event-gallery-actions'}>
          <Button
            disableRipple
            className="button-primary cancel-button"
            onClick={this.props.closeModal}
          >
            <span className="button-primary-label">Cancel</span>
          </Button>
          <Button
            disabled={!this.state.images.length}
            disableRipple
            className={['button-primary', 'button-orange'].join(' ')}
            onClick={this.uploadImages}
          >
            <span className="button-primary-label label-white">Upload Images</span>
          </Button>
        </DialogActions>
      </Fragment>
    );
  }
}

EventGalleryModal.propTypes = {
  event: PropType.object.isRequired,
  closeModal: PropType.func.isRequired,
  uploadEventImages: PropType.func.isRequired
};

export default connect(null, {
  closeModal,
  uploadEventImages
})(EventGalleryModal);
