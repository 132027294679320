import React, { ChangeEvent, FC, Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { STATES } from '../../../../assets/data/us_states';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/icon_close_small.svg';
import { ReactComponent as SearchActiveIcon } from '../../../../assets/icons/icon_search_active.svg';
import { ReactComponent as SearchInactiveIcon } from '../../../../assets/icons/icon_search_inactive.svg';
import { useAppSelector } from '../../../../helpers/store';
import { RootState } from '../../../../reducers/index.types';
import { SelectField } from '../../../../v2/components/SelectorField/index';
import { CityFilterInput } from './CityFilterInput';
import { contactActions } from '../../contacts.ducks';

export const SearchCityStateFilter: FC = () => {
  const dispatch = useDispatch();
  const actions = bindActionCreators(contactActions, dispatch);

  const { filteredCity, filteredState, searchValue, filterCitySuggestions } = useAppSelector(
    (state: RootState) => state.contactsData
  );

  const [localSearchValue, setLocalSearchValue] = useState<string>(searchValue);
  const [localCityValue, setLocalCityValue] = useState<string>(filteredCity);

  const largeWidthScreen = window ? window.innerWidth > 600 : false;

  const handleSearchIconClick = () => {
    if (localSearchValue?.length >= 3 || localSearchValue?.length === 0) {
      actions.updateSearchState(localSearchValue);
    }
  };
  const updateSearchValue = (event?: ChangeEvent<HTMLInputElement>) => {
    setLocalSearchValue(event?.target.value ?? '');
  };

  const handleSearchClearClick = () => {
    setLocalSearchValue('');
    actions.updateSearchState('');
  };

  const handleSearchKeyUp = (event: React.KeyboardEvent) => {
    // If the user presses enter, fire search handler
    if ((event.key === 'Enter' && localSearchValue.length >= 3) || localSearchValue.length === 0) {
      actions.updateSearchState(localSearchValue);
    }
  };
  const handleCityChange = (value: string) => {
    setLocalCityValue(value);
  };

  const handleCityFilterClear = () => {
    setLocalCityValue('');
    actions.updateFilteredCity('');
  };

  const handleSelectState = (name: string, state: string) => {
    handleCityFilterClear();
    actions.updateFilteredState(state);
  };

  const handleSelectCitySuggestion = (city: string) => {
    actions.updateFilteredCity(city);
  };

  const SearchIcon = localSearchValue?.length >= 3 ? SearchActiveIcon : SearchInactiveIcon;

  return (
    <Fragment>
      <div className="contact-search-icon-container">
        <SearchIcon className="icon-search" onClick={handleSearchIconClick} />
        <input
          id="contact-search-field"
          className={[
            'contact-filter-search',
            localSearchValue && localSearchValue.length >= 3 ? 'active' : null
          ].join(' ')}
          type="text"
          placeholder="Search contacts"
          value={localSearchValue}
          onChange={updateSearchValue}
          onKeyUp={handleSearchKeyUp}
        />
        {searchValue ? <CloseIcon className="icon-close" onClick={handleSearchClearClick} /> : null}
      </div>
      {largeWidthScreen ||
      (searchValue && searchValue.length >= 0) ||
      localCityValue ||
      filteredState ? (
        <Fragment>
          <SelectField
            containerClassName="contact-filter-state-input-container"
            className="contact-filter-state-input"
            placeholder={'State'}
            options={STATES}
            onChange={handleSelectState}
            inputValue={filteredState}
          />
          <div className="contact-filter-city-input-container">
            <CityFilterInput
              className="contact-filter-city-input"
              value={localCityValue}
              isDisabled={filteredState}
              filterCitySuggestions={filterCitySuggestions}
              onChange={handleCityChange}
              onClear={handleCityFilterClear}
              onSelectSuggestion={handleSelectCitySuggestion}
            />
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};
