import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { openModal } from '../../actions/common.actions';
import * as MODAL_COMPONENTS from '../../constants/modals';
import { CANEXPORT, checkUserPermission } from '../../util/userPermission';
import {
  KARYAKARTA_ASSIGNED_SUCCESS,
  KARYAKARTA_ASSIGNED_ERROR,
  CONTACT_UPDATED_SUCCESS,
  CONTACT_UPDATED_ERROR
} from '../../constants/toasterMessages';
import { FILTER_FORM } from '../../constants/forms';
import Stage from '../../util/stage';
import { initializeContactForm } from '../../actions/contactForm.actions';
import { RootState } from '../../reducers/index.types';
import { MembersType } from '../../types';
import FollowUpModal from './components/FollowUpModal';
import { selectCanAddMember } from '../../reducers/authentication.reducer';
import { CardsListHeaders } from './components/CardsListHeaders';
import { CardsList } from './components/CardsList/CardsList';
import { contactActions } from './contacts.ducks';
import { styles } from './styles';
import { PageHeader } from './components/PageHeader/PageHeader';
import { updateContactAPI, updateFollowUpContactAPI } from '../../apis/contactAPIs';
import useAPI from '../Admin/FamilyTree/hooks/useAPI';
import { useMembers } from './useMembers';
import { useAppDispatch, useAppSelector } from '../../helpers/store';
import FilterPanel from '../../components/filter-panel/FilterPanel';
import { IFilterFormValues } from '../../components/filter-panel/FilterForm.types';

//TODO Fix this
const useStyles = makeStyles(styles as any);

const ContactsView = () => {
  const dispatch = useAppDispatch();
  const { callApi } = useAPI();
  //TODO check alternative to bindActionCreators
  const actions = bindActionCreators(
    { ...contactActions, openModal, initializeContactForm },
    dispatch
  );

  const {
    contacts: contactsData,
    contactsTotalPages = 0,
    contactsTotalNumber = 0,
    stage: contactsLoadingStage,
    searchValue,
    selectedContacts,
    sortValue,
    filteredCity,
    filteredState
  } = useAppSelector((state: RootState) => state.contactsData);

  const { getMembers, loading } = useMembers();

  const canAddMember = useAppSelector(selectCanAddMember);

  const displayLoader = loading || contactsLoadingStage === Stage.Loading;

  const userAccess = useAppSelector((state: RootState) => state.userAccess);
  const form = useAppSelector((state: RootState) => state.form);
  const canExport = checkUserPermission(userAccess, CANEXPORT);
  const largeWidthScreen = window ? window.innerWidth > 600 : false;

  const filterFormValues: IFilterFormValues = form?.[FILTER_FORM]?.values ?? {};

  const [cardView, setCardView] = useState(
    localStorage.getItem('isCardViewPref')
      ? localStorage.getItem('isCardViewPref') === 'true'
      : false
  );
  const [displayFollowUpModal, setDisplayFollowUpModal] = useState(false);
  const [displayFilterPanelModal, setDisplayFilterPanelModal] = useState(false);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const classes = useStyles();

  useEffect(() => {
    if (!localStorage.getItem('isCardViewPref')) {
      toggleContactView();
    }

    return () => {
      actions.resetContactsList();
    };
  }, []);

  const filterValues = useMemo(() => {
    return {
      adminAttributes: filterFormValues?.adminAttributes?.map((type) => type.value),
      attributes: filterFormValues?.attributes?.map((attr) => attr.value),
      audiences: filterFormValues?.audiences?.map((type) => type.value),
      contactStatus: filterFormValues?.unverified ? 'unverified' : undefined,
      emailType: filterFormValues?.emailType,
      city: filteredCity ? filteredCity?.toLowerCase() : undefined,
      state: filteredState ? filteredState?.toLowerCase() : undefined,
      followUp: filterFormValues?.followUp?.unassigned ? 'unassigned' : undefined,
      gender: filterFormValues?.gender,
      mandal: filterFormValues?.mandal ?? undefined,
      maritalStatus: filterFormValues?.maritalStatus,
      muktType: filterFormValues?.muktType?.map((type) => type.value),
      noReference: filterFormValues?.noReference || undefined
    };
  }, [filteredCity, filteredState, filterFormValues]);

  const searchParams = useRef('');

  const filterOptionsSelected = useMemo(() => {
    if (!filterValues || typeof filterValues !== 'object') {
      return false;
    }

    if (
      (filterValues.adminAttributes && filterValues.adminAttributes.length > 0) ||
      (filterValues.attributes && filterValues.attributes.length > 0) ||
      (filterValues.audiences && filterValues.audiences.length > 0) ||
      (filterValues.emailType && filterValues.emailType.trim() !== '') ||
      (filterValues.followUp && filterValues.followUp === 'unassigned') ||
      (filterValues.gender && filterValues.gender.trim() !== '') ||
      (filterValues.mandal && filterValues.mandal.trim() !== '') ||
      (filterValues.maritalStatus && filterValues.maritalStatus.trim() !== '') ||
      (filterValues.muktType && filterValues.muktType.length > 0) ||
      filterValues.noReference ||
      filterValues.contactStatus === 'unverified' ||
      filterValues.city ||
      filterValues.state
    ) {
      return true;
    }

    return false;
  }, [filterValues]);

  useEffect(() => {
    const hasSearchParamsChanged =
      searchParams.current !== JSON.stringify({ ...filterValues, searchValue });
    if (hasSearchParamsChanged) {
      searchParams.current = JSON.stringify({ ...filterValues, searchValue });
      getMembers({
        pageNumber: 1,
        filter: filterValues,
        search: searchValue,
        onCompleted: (page: number) => {
          setCurrentPageNumber(page);
        }
      });
    }
  }, [filterValues, sortValue, searchValue]);

  const observer = useRef<IntersectionObserver | null>(null);

  const loadMoreRef = useCallback(
    (node: HTMLDivElement) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        // only if intersecting meaning, its coming into view, we fetch more
        if (
          entries?.[0]?.isIntersecting &&
          currentPageNumber < contactsTotalPages &&
          !displayLoader
        ) {
          getMembers({
            pageNumber: currentPageNumber + 1,
            filter: filterValues,
            search: searchValue,
            onCompleted: (page: number) => {
              setCurrentPageNumber(page);
            }
          });
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    [currentPageNumber, contactsTotalPages, displayLoader]
  );

  const toggleContactView = useCallback(() => {
    localStorage.setItem('isCardViewPref', (!cardView).toString());
    setCardView(!cardView);
  }, [cardView]);

  const handleAddNewClick = () => {
    actions.initializeContactForm(null);
    actions.openModal(MODAL_COMPONENTS.CONTACT_FORM, { maxWidth: 'sm' });
  };

  const handleExport = () => {
    actions.getExportData();
  };

  const handleSortMenuClick = useCallback((value: string) => {
    actions.updateSortValue(value);
    getMembers({
      pageNumber: 1,
      search: searchValue,
      filter: filterValues,
      sort: value
    });
  }, []);

  const handleFilterClick = () => {
    setDisplayFilterPanelModal(true);
  };

  const openFollowUpModal = useCallback(() => {
    setDisplayFollowUpModal(true);
  }, []);

  const setNoReference = async () => {
    //TODO change to mutation;
    selectedContacts.map(async (contact: any) => {
      const updatedContact = { ...contact, noReference: !contact.noReference };
      callApi({
        apiFunction: updateContactAPI,
        dataObject: updatedContact,
        successMessage: CONTACT_UPDATED_SUCCESS,
        errorMessage: CONTACT_UPDATED_ERROR
      });
      getMembers({
        pageNumber: 1,
        search: searchValue,
        filter: filterValues,
        sort: sortValue
      });
    });
  };

  const closeFollowUpModal = useCallback(() => {
    setDisplayFollowUpModal(false);
  }, []);

  // selectedKaryakartas is an array of user objects
  const handleSubmitFollowUp = (selectedKaryakartas: any) => {
    if (selectedKaryakartas) {
      const dataObject = {
        followUp: selectedKaryakartas.map((kk: any) => kk.memberID),
        members: selectedContacts?.map((member: MembersType) => member._id)
      };
      // SUBMIT API CALL HERE
      //TODO Change to Mutation
      callApi({
        apiFunction: updateFollowUpContactAPI,
        dataObject: dataObject,
        successMessage: KARYAKARTA_ASSIGNED_SUCCESS,
        errorMessage: KARYAKARTA_ASSIGNED_ERROR
      });
      getMembers({
        pageNumber: 1,
        search: searchValue,
        filter: filterValues,
        sort: sortValue
      });
      actions.clearSelectedContacts();
    }
    closeFollowUpModal();
  };

  return (
    <div className={classNames([classes.container, 'contacts-view-container'])}>
      <Dialog
        className="contacts-selected-dialog"
        fullScreen={false}
        open={displayFollowUpModal}
        aria-labelledby="responsive-dialog-title"
      >
        <FollowUpModal
          onClose={(data) => {
            handleSubmitFollowUp(data);
          }}
          // @ts-ignore
          selectedContacts={selectedContacts}
        />
      </Dialog>
      <div className={classes.view}>
        <div className={classNames([classes.header, 'contacts-header'])}>
          <PageHeader
            actionsListProps={{
              canExport,
              cardView,
              handleExport,
              handleFilterClick,
              handleSortMenuClick,
              toggleContactView,
              largeWidthScreen,
              openFollowUpModal,
              selectedContacts,
              sortValue,
              setNoReference
            }}
            canAddContact={canAddMember}
            contactsCount={contactsTotalNumber}
            handleClickNewContact={handleAddNewClick}
          />
        </div>

        <section className={'contacts-data-container'}>
          <section className={!cardView ? 'contacts-data-container-list' : ''}>
            {/* RENDER CARD_HEADERS */}
            {!cardView && <CardsListHeaders />}

            <div
              id={'parent-component'}
              className={classNames({
                'contacts-data-card-list-container': true,
                'contacts-data-lowered':
                  typeof filterFormValues === 'object' && filterOptionsSelected,
                'contacts-data-leveled': !filterFormValues || !filterOptionsSelected,
                'contacts-list-view': !cardView
              })}
            >
              {/* RENDER CARDS */}
              <CardsList
                //Fix Typecasting
                contacts={contactsData}
                cardView={cardView}
                openFollowUpModal={openFollowUpModal}
                showLoading={Boolean(
                  contactsData?.length && currentPageNumber < contactsTotalPages
                )}
                loadMoreRef={loadMoreRef}
              />
            </div>
            {displayLoader ? (
              <LoadingSpinner className={classes.loader} />
            ) : contactsData?.length === 0 && searchValue.length ? (
              <div className={classes.emptyMessage}>No results found. Try again.</div>
            ) : null}

            <Dialog
              className="contacts-selected-dialog"
              fullScreen={window.innerWidth < 768}
              maxWidth={'sm'}
              fullWidth
              open={displayFilterPanelModal}
              aria-labelledby="responsive-dialog-title"
              onClose={() => setDisplayFilterPanelModal(false)}
            >
              <FilterPanel onClose={() => setDisplayFilterPanelModal(false)} />
            </Dialog>
          </section>
        </section>
      </div>
    </div>
  );
};

export default ContactsView;
