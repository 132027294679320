import React from 'react';
import { reduxForm, Field, FieldArray, formValueSelector, change } from 'redux-form';
import { Radio, FormControlLabel } from '@material-ui/core';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { EVENT_FORM } from '../../constants/forms';
import validate from './validate';
import {
  renderRadioGroup,
  renderTextField,
  renderSelectField,
  renderAddressField,
  renderDatePicker,
  renderScheduleFields,
  renderEventNumbers,
  renderImageGroup,
  TargetSection
} from '../form-fields';
import { STATES } from '../../assets/data/us_states';
import icon_radio_selected_small from '../../assets/icons/icon_radio_selected_small.svg';
import icon_radio_unselected_small from '../../assets/icons/icon_radio_unselected_small.svg';
import { MdxEditorWrapper } from '../form-fields/MDXEditorWrapper';
import { useAppDispatch, useAppSelector } from '../../helpers/store';

dayjs.extend(isSameOrBefore);

interface EventsFormProps {
  initialValues: any;
  isDevMode: string;
}

const EventsForm: React.FC<EventsFormProps> = ({ isDevMode, initialValues }) => {
  const dispatch = useAppDispatch();
  const { duration, start_date, end_date } = useAppSelector(
    (state) => formValueSelector(EVENT_FORM)(state, 'duration', 'start_date', 'end_date')
    // formValueSelector(EVENT_FORM)(
    //   state,
    //   'duration',
    //   'start_date',
    //   'end_date',
    //   'enableTravelArrangements',
    //   'enableCheckinInstructions'
    // )
  );
  const audiencesList = useAppSelector(
    (state) => state.contactsData.contactOptions.audiencesList.events
  );

  const eventDurationOptions = ['single day', 'multi day'];
  const isMultiDayEvent = duration === eventDurationOptions[1];
  const EndDate = isMultiDayEvent ? end_date : start_date;
  const eventDates = getEventDates(start_date, EndDate);
  const readOnly = initialValues.id ? !!initialValues : false;
  const eventId = isDevMode === 'true' ? initialValues.contact[0]._id : null;
  const titleMaxChars = 140;
  const descriptionMaxChars = 700;

  const handleDurationChange = (_e: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    if (newValue === 'single day') {
      dispatch(change(EVENT_FORM, 'end_date', start_date));
    } else if (dayjs(start_date).isAfter(dayjs(end_date))) {
      dispatch(change(EVENT_FORM, 'end_date', start_date));
    }
  };

  const handleStartDateChange = (_e: React.ChangeEvent<HTMLInputElement>, newValue: string) => {
    if (isMultiDayEvent) {
      dispatch(change(EVENT_FORM, 'end_date', newValue));
    }
  };

  return (
    <div className="events-form">
      <div className="field-event-custom-targeting">
        <Field
          required
          name="audiences"
          disabled={readOnly}
          component={TargetSection}
          audiencesList={audiencesList}
        />
      </div>
      <div className="field-event-images">
        <FieldArray
          label="Event Image"
          name="images"
          component={renderImageGroup as any} //TODO remove this after upgrading redux-form
          required
        />
      </div>
      <div className="field-event-name">
        <Field
          required
          label={`Event Name (max. ${titleMaxChars} characters)`}
          name="title"
          maxChars={titleMaxChars}
          component={renderTextField}
          placeholder="Enter event title"
        />
      </div>
      <div className="field-event-description">
        <Field
          label={`Event Description (max. ${descriptionMaxChars} characters)`}
          name="description"
          component={MdxEditorWrapper}
          maxChars={descriptionMaxChars}
          formName="events"
          placeholder="Enter event description"
        />
      </div>
      <div className="horizontal-line line-1"></div>
      <div className="title-venue margin-md-bottom margin-sm-top">
        <label className="section-label" style={{ marginRight: '0.5rem' }}>
          Venue / Location
        </label>
      </div>
      <div className="field-venue-name">
        <Field
          label="Event Venue Name"
          name="venue.name"
          component={renderTextField}
          placeholder="Enter venue name"
        />
      </div>
      <div className="field-address-1">
        <Field
          required
          label="Address 1"
          name="venue.address1"
          component={renderAddressField}
          placeholder="Address 1"
        />
      </div>
      <div className="field-address-2">
        <Field
          label="Address 2"
          name="venue.address2"
          component={renderTextField}
          placeholder="Address 2"
        />
      </div>
      <div className="field-address-city">
        <Field
          required
          label="City"
          name="venue.city"
          component={renderTextField}
          placeholder="Enter Address"
        />
      </div>
      <div className="field-address-state-zip">
        <div className="field-address-state">
          <Field
            required
            label="State"
            name="venue.state"
            component={renderSelectField}
            placeholder="State"
            options={STATES}
          />
        </div>
        <div className="field-address-zip">
          <Field
            required
            label="Zip Code"
            name="venue.zip"
            component={renderTextField}
            placeholder="Zip Code"
          />
        </div>
      </div>
      <div className="horizontal-line line-2"></div>
      <div className="title-dates margin-md-bottom margin-sm-top">
        <label className="section-label" style={{ marginRight: '0.5rem' }}>
          Dates
        </label>
      </div>
      <div className="field-event-duration">
        <Field
          required
          label="Event Duration"
          name="duration"
          component={renderRadioGroup as any} //TODO remove this after upgrading redux-form
          onChange={handleDurationChange as any} //TODO remove this after upgrading redux-form
        >
          {eventDurationOptions.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option}
              control={
                <Radio
                  disableRipple
                  checkedIcon={<img src={icon_radio_selected_small} />}
                  icon={<img src={icon_radio_unselected_small} />}
                />
              }
              label={option}
            />
          ))}
        </Field>
      </div>
      <div className="field-event-start-date">
        <Field
          label="Event Start Date"
          name="start_date"
          required
          component={renderDatePicker}
          placeholder="mm/dd/yyyy"
          onChange={handleStartDateChange as any}
        />
      </div>
      <div className="field-event-end-date">
        {isMultiDayEvent && (
          <Field
            label="Event End Date"
            name="end_date"
            format={(value: string) => {
              if (value && dayjs(value).isValid()) {
                return dayjs(value).format('YYYY-MM-DD');
              }
            }}
            parse={(value: string) => {
              const [year, month, day] = value.split('-');
              return dayjs(`${year}-${month}-${day}`).toISOString();
            }}
            readOnly={!isMultiDayEvent}
            required={isMultiDayEvent}
            component={renderDatePicker}
            placeholder="mm/dd/yyyy"
          />
        )}
      </div>
      <div className="horizontal-line line-3" />
      <div className="field-event-schedule">
        <div className="margin-md-bottom margin-sm-top">
          <label className="section-label" style={{ marginRight: '0.5rem' }}>
            Schedule
          </label>
        </div>
        <FieldArray
          name="schedule"
          eventDates={eventDates}
          component={renderScheduleFields as any} //TODO remove this after upgrading redux-form
        />
      </div>
      <div className="horizontal-line line-4"></div>
      <div className="field-event-contact-numbers">
        <div className="margin-md-bottom margin-sm-top">
          <label className="section-label" style={{ marginRight: '0.5rem' }}>
            Important Contact Numbers
          </label>
        </div>
        {/* TODO remove this after upgrading redux-form */}
        <FieldArray name="contact" component={renderEventNumbers as any} />
      </div>

      <span className="text-small-disable">{eventId}</span>
    </div>
  );
};

export default reduxForm({
  form: EVENT_FORM,
  validate: validate as any
})(EventsForm as any); //TODO remove this after upgrading redux-form

function getEventDates(start_date: string, end_date: string) {
  if (start_date) {
    const eventDates = [];
    let currentDate = dayjs(start_date);

    if (end_date) {
      const finalDate = dayjs(end_date);
      while (currentDate.isSameOrBefore(finalDate)) {
        eventDates.push(dayjs(currentDate).utc().format('YYYY-MM-DD'));
        currentDate = dayjs(currentDate).add(1, 'day');
      }
    } else {
      eventDates.push(dayjs(start_date).utc().format('YYYY-MM-DD'));
    }
    return eventDates;
  } else {
    return [];
  }
}
