import { bindActionCreators } from 'redux';
import { closeModal } from '../../actions/common.actions';
import FilterForm from './FilterForm';
import icon_close from '../../assets/icons/icon_cross.svg';
import icon_filter from '../../assets/icons/icon_filter.svg';
import { useAppDispatch } from '../../helpers/store';

const FilterPanel = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const actions = bindActionCreators({ closeModal }, dispatch);

  return (
    <div className="filter-panel">
      <div className="padding-left padding-right filter-panel-header">
        <div className="filter-panel-top-row">
          <img src={icon_filter} />
          <span className="title-text padding-xsm-right">All filters</span>
          <img
            className="filter-form-dismiss"
            onClick={() => {
              onClose();
              actions.closeModal();
            }}
            src={icon_close}
          />
        </div>
      </div>
      <FilterForm />
    </div>
  );
};

export default FilterPanel;
