import React, { FC, useMemo, useCallback } from 'react';
import { Chip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { change } from 'redux-form';
import { FILTER_FORM } from '../../../constants/forms';
import { RootState } from '../../../reducers/index.types';

import icon_close_mini from '../../../assets/icons/icon_close_mini.svg';
import {
  IFilterFormOption,
  IFilterFormValues
} from '../../../components/filter-panel/FilterForm.types';

export const FilterChips: FC = () => {
  const form = useSelector((state: RootState) => state.form);
  const filterFormValues: IFilterFormValues = form?.[FILTER_FORM]?.values ?? {};

  const dispatch = useDispatch();

  const handleFilterOptionClick = useCallback(
    (event: any, filterFormValues: IFilterFormValues, arrayOption: IFilterFormOption | null) => {
      const filterOption = event.currentTarget.getAttribute('data-parent')
        ? event.currentTarget.getAttribute('data-parent')
        : event.target.parentElement.getAttribute('data-parent');

      if (filterFormValues && typeof filterFormValues === 'object') {
        const filterFormKeys = Object.keys(filterFormValues);
        if (filterFormKeys && filterFormKeys.length) {
          const foundKey = filterFormKeys.find((key) => filterOption.includes(key));
          if (foundKey && arrayOption) {
            const newFilterValues = getFilterChipFormValues(
              filterFormValues,
              foundKey,
              arrayOption
            );
            dispatch(change(FILTER_FORM, foundKey, newFilterValues));
          } else if (foundKey) {
            dispatch(change(FILTER_FORM, foundKey, ''));
          }
        }
      }
    },
    [dispatch, filterFormValues]
  );

  const filterChipValues = useMemo(() => {
    const chips: {
      parent: string;
      onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
      label: string;
    }[] = [];

    if (filterFormValues && typeof filterFormValues === 'object') {
      if (filterFormValues.adminAttributes) {
        filterFormValues.adminAttributes.forEach((option) => {
          chips.push({
            parent: `adminAttributes.${option.value}`,
            onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              handleFilterOptionClick(e, filterFormValues, option),
            label: option.name
          });
        });
      }

      if (filterFormValues.attributes) {
        filterFormValues.attributes.forEach((option) => {
          chips.push({
            parent: `attributes.${option.value}`,
            onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              handleFilterOptionClick(e, filterFormValues, option),
            label: option.name
          });
        });
      }

      if (filterFormValues.audiences) {
        filterFormValues.audiences.forEach((option) => {
          chips.push({
            parent: `audiences.${option.value}`,
            onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              handleFilterOptionClick(e, filterFormValues, option),
            label: option.name
          });
        });
      }

      if (filterFormValues.emailType) {
        chips.push({
          parent: `emailType.${filterFormValues.emailType}`,
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleFilterOptionClick(e, filterFormValues, null),
          label: filterFormValues.emailType
        });
      }

      if (filterFormValues.followUp?.unassigned) {
        chips.push({
          parent: 'followUp.unassigned',
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleFilterOptionClick(e, filterFormValues, null),
          label: 'Unassigned'
        });
      }

      if (filterFormValues.gender) {
        chips.push({
          parent: `gender.${filterFormValues.gender}`,
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleFilterOptionClick(e, filterFormValues, null),
          label: filterFormValues.gender
        });
      }

      if (filterFormValues.mandal) {
        chips.push({
          parent: `mandal.${filterFormValues.mandal}`,
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleFilterOptionClick(e, filterFormValues, null),
          label: filterFormValues.mandal
        });
      }

      if (filterFormValues.maritalStatus) {
        chips.push({
          parent: `maritalStatus.${filterFormValues.maritalStatus}`,
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleFilterOptionClick(e, filterFormValues, null),
          label: filterFormValues.maritalStatus
        });
      }

      if (filterFormValues.muktType) {
        filterFormValues.muktType.forEach((option) => {
          chips.push({
            parent: `muktType.${option.value}`,
            onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
              handleFilterOptionClick(e, filterFormValues, option),
            label: option.name
          });
        });
      }

      if (filterFormValues.noReference) {
        chips.push({
          parent: 'noReference',
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleFilterOptionClick(e, filterFormValues, null),
          label: 'No Reference'
        });
      }

      if (filterFormValues.unverified) {
        chips.push({
          parent: 'unverified',
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            handleFilterOptionClick(e, filterFormValues, null),
          label: 'Unverified'
        });
      }
    }

    return chips;
  }, [filterFormValues, handleFilterOptionClick]);

  return (
    <div className="contacts-filters-container">
      {filterChipValues.map((chip, index) => (
        <Chip
          data-parent={chip.parent}
          onDelete={chip.onClick} // Ensure this is consistent
          key={`${index}-${chip.label}`}
          onClick={chip.onClick}
          deleteIcon={<img style={{ height: 8, width: 8 }} src={icon_close_mini} alt="delete" />}
          className="chip"
          label={<span className="chip-label">{chip.label}</span>}
        />
      ))}
    </div>
  );
};

function getFilterChipFormValues(
  filterFormValues: IFilterFormValues,
  foundKey: string,
  option: IFilterFormOption
): IFilterFormOption[] {
  let optionArray: IFilterFormOption[] | undefined = [];
  switch (foundKey) {
    case 'muktType':
      optionArray = filterFormValues.muktType;
      break;
    case 'adminAttributes':
      optionArray = filterFormValues.adminAttributes;
      break;
    case 'attributes':
      optionArray = filterFormValues.attributes;
      break;
    case 'audiences':
      optionArray = filterFormValues.audiences;
      break;
    default:
      console.error('Unknown Filter Form Value');
  }
  return optionArray?.filter((optionArrayValue) => optionArrayValue.value !== option.value) || [];
}
