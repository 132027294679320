import { Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { ReactComponent as IconSort } from '../../../../assets/icons/icon_sort.svg';
import { ReactComponent as IconCardView } from '../../../../assets/icons/icon_card_view.svg';
import { ReactComponent as IconListView } from '../../../../assets/icons/icon_list_view.svg';
import { ReactComponent as IconExport } from '../../../../assets/icons/icon_export.svg';
import { ReactComponent as IconFilter } from '../../../../assets/icons/icon_filter.svg';
import { ReactComponent as IconFollowUp } from '../../../../assets/icons/icon_follow_up.svg';
import { ReactComponent as IconNoReference } from '../../../../assets/icons/icon_noReference.svg';
import { sortOptions } from '../../../../constants/sortOptions';
import { StyledContactListActionButton } from './ContactListActions.styles';

const SortButton = ({
  onClick,
  sortValue
}: {
  onClick: (value: string) => void;
  sortValue: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const openSortMenu = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget as Element);
  };

  const closeSortMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <StyledContactListActionButton onClick={openSortMenu}>
        <IconSort />
        <span className="text-small">Sort</span>
      </StyledContactListActionButton>
      <Menu
        id="sortMenu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={Boolean(anchorEl)}
        onClose={closeSortMenu}
      >
        {sortOptions.map((option, index) => {
          return (
            <MenuItem
              key={index}
              selected={sortValue === option.value}
              value={option.value}
              onClick={() => {
                onClick(option.value);
                closeSortMenu();
              }}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

const GenericActionButton = ({
  Icon,
  label,
  onClick
}: {
  Icon: FC;
  label: string;
  onClick: () => void;
}) => {
  return (
    <StyledContactListActionButton key={label} onClick={onClick}>
      <Icon />
      <span className="text-small">{label}</span>
    </StyledContactListActionButton>
  );
};

export interface IBuildContactsListActions {
  canExport: boolean;
  cardView: boolean;
  handleExport: () => void;
  handleFilterClick: () => void;
  handleSortMenuClick: (value: string) => void;
  largeWidthScreen: boolean;
  openFollowUpModal: () => void;
  selectedContacts: any[];
  sortValue: string;
  toggleContactView: () => void;
  setNoReference: () => void;
}

export const buildContactsListActions = ({
  canExport,
  cardView,
  handleExport,
  handleFilterClick,
  handleSortMenuClick,
  largeWidthScreen,
  openFollowUpModal,
  selectedContacts,
  sortValue,
  toggleContactView,
  setNoReference
}: IBuildContactsListActions) => {
  const listActions = [];
  if (selectedContacts?.length) {
    listActions.push(
      <GenericActionButton
        key={'followupAction'}
        Icon={IconFollowUp}
        label={'Follow Up'}
        onClick={openFollowUpModal}
      />
    );
    listActions.push(
      <GenericActionButton
        key={'noReferenceAction'}
        Icon={IconNoReference}
        label={'No Reference'}
        onClick={setNoReference}
      />
    );
  }
  listActions.push(
    <SortButton key={'sortAction'} onClick={handleSortMenuClick} sortValue={sortValue} />
  );

  if (largeWidthScreen) {
    listActions.push(
      <GenericActionButton
        key={'contactViewAction'}
        Icon={cardView ? IconListView : IconCardView}
        label={cardView ? 'List View' : 'Card View'}
        onClick={toggleContactView}
      />
    );
  }
  listActions.push(
    <GenericActionButton
      key={'filterAction'}
      Icon={IconFilter}
      label={'Filter'}
      onClick={handleFilterClick}
    />
  );

  if (largeWidthScreen && canExport) {
    listActions.push(
      <GenericActionButton
        key={'exportAction'}
        Icon={IconExport}
        label={'Export'}
        onClick={handleExport}
      />
    );
  }

  return listActions;
};
