import React, { Fragment, Component } from 'react';
import PropType from 'prop-types';
import { connect } from 'react-redux';
// material
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
// project files
import { DELETE_CONTACT, INVITE_CONTACT, INVITE_CONTACT_TO_WORKSHOP } from '../../constants/menuOptions';
import getMenuOptions from '../../util/getMenuOptions';
import { CONTACT_PANEL_MENU } from '../../constants/menus';
import { HoverImage } from '../hover-image/HoverImage';
import icon_overflow from '../../assets/icons/icon_overflow.svg';
import icon_overflow_active from '../../assets/icons/icon_overflow_active.svg';
import { openModal } from '../../actions/common.actions';
import * as MODAL_COMPONENTS from '../../constants/modals';
import { checkUserPermission, ISSUPERADMIN, CANDELETE } from '../../util/userPermission';
import { sendWorkshopInvitation } from '../../apis/invitationAPIs';
import { showToaster } from '../../v2/actions/Toaster.action';
import { WORKSHOP_INVITATION_ERROR, WORKSHOP_INVITATION_SUCCESS } from '../../constants/toasterMessages';
import { commonActions } from '../../constants/actions';
import { contactActions } from '../../views/Contacts/contacts.ducks';
import { growthbook } from '../../helpers/growthbook';
import { CONTACTS_PAGE_SIZE } from '../../views/Contacts/Contacts.const';

class ContactCardMenu extends Component {
  constructor (props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleInviteContact = () => {
    const { contact } = this.props;
    const { email, phones } = contact.contactDetails;
    const isGmailRegex = new RegExp(/(\W|^)[\w.+-]*@gmail\.com(\W|$)/i);
    const isValidGmail = isGmailRegex.test(email);
    const cellNumber = phones.find(phoneNumber => phoneNumber.type === 'Cell');
    let errorMessage = '';

    if (cellNumber === undefined) {
      errorMessage = 'A cell phone number is required to invite a karyakarta to Sampark.';
    } else if (cellNumber.value === '') {
      errorMessage = 'A cell phone number is required to invite a karyakarta to Sampark.';
    } else if (!isValidGmail) {
      errorMessage = 'You can only invite a karyakarta with a gmail address at the moment.';
    }

    if (errorMessage.length === 0) {
      this.props.openModal(MODAL_COMPONENTS.INVITE_MEMBER_MODAL, {contact});
    } else {
      this.props.openModal(MODAL_COMPONENTS.ERROR_MODAL, { errorMessage });
    }
  }

  // handle delete button
  handleDeleteContact = () => {
    const {contact} = this.props;
    const selectedContacts = [];
    selectedContacts.push(contact);
    this.props.openModal(
      MODAL_COMPONENTS.DELETE_CONTACTS_MODAL, { selectedContacts }
    );
  }

  handleInviteContactWorkshop = async () => {
    const { contact, showToaster, getMembers, searchValue = '' } = this.props;
    const { email } = contact.contactDetails;

    // Fire API to send invite workshop email to contact
    const invitation = await sendWorkshopInvitation(contact._id);
    // Show toaster that user has been invited
    if (invitation.success) {
      showToaster(WORKSHOP_INVITATION_SUCCESS(email), commonActions.SUCCESS_TOASTER);
      getMembers({ pageNumber: 1, search: searchValue });
    } else {
      // Show toaster that invitation failed
      showToaster(WORKSHOP_INVITATION_ERROR(email), commonActions.ERROR_TOASTER);
    }
  };

  // open menu
  handleMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  // handle menu options click
  handleMenuClose = value => {
    this.setState(
      { anchorEl: null },
      () => {
        switch (value) {
          case INVITE_CONTACT.value:
            this.handleInviteContact();
            break;
          case DELETE_CONTACT.value:
            this.handleDeleteContact();
            break;
          case INVITE_CONTACT_TO_WORKSHOP.value:
            this.handleInviteContactWorkshop();
            break;
          default:
        }
      }
    );
  }

  renderOptionName = (option, style) => {
    if (option.tooltip) {
      return (
        <Tooltip placement="top" title={option.tooltip}>
          <div>{option.name}</div>
        </Tooltip>
      );
    } else {
      return (
        <div style={style}>{option.name}</div>
      );
    }
  }

  render () {
    const { contact, displayHorizontal, isSuperAdmin, canDelete, audiences } = this.props;
    const { anchorEl } = this.state;
    const menuOptions = getMenuOptions(CONTACT_PANEL_MENU, contact, isSuperAdmin, audiences);

    return (
      <Fragment>
        <HoverImage
          style={{display: 'flex', transform: displayHorizontal ? 'rotate(90deg)' : null}}
          src={icon_overflow}
          hoverSrc={icon_overflow_active}
          onClick={this.handleMenuOpen} />
        <Menu
          MenuListProps={{
            disablePadding: true
          }}
          style={{marginTop: '0.5rem'}}
          anchorEl={anchorEl}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
          getContentAnchorEl={null}
          open={Boolean(anchorEl)}
          onClose={() => {
            this.handleMenuClose();
          }}>
          {menuOptions.map((option, index) => {
            let style = {};
            if (option.value === DELETE_CONTACT.value && !canDelete) {
              option.disabled = true;
            }
            if (option.value === DELETE_CONTACT.value && option.disabled) {
              if (isSuperAdmin) {
                option.disabled = false;
              }
              return (
                <MenuItem
                  key={index}
                  disabled={option.disabled}
                  onClick={() => {
                    this.handleMenuClose(option.value);
                  }}>
                  {option.name}
                </MenuItem>
              );
            }
            if (option.value === INVITE_CONTACT_TO_WORKSHOP.value) {
              style = { color: '#AE5035' };
            }
            return (
              <MenuItem
                key={index}
                disabled={option.disabled}
                onClick={() => {
                  this.handleMenuClose(option.value);
                }}
                style={{ pointerEvents: 'inherit' }}
                >
                {this.renderOptionName(option, style)}
              </MenuItem>
            );
          })}
        </Menu>
      </Fragment>
    );
  }
};

const mapStateToProps = (state, props) => {
  const isSuperAdmin = checkUserPermission(state.userAccess, ISSUPERADMIN);
  return {
    isSuperAdmin,
    canDelete: checkUserPermission(state.userAccess, CANDELETE),
    audiences: state.userAccess?.userAccount?.audiences,
    searchValue: state.contactsData.searchValue
  };
};

export default connect(
  mapStateToProps, {
    openModal,
    showToaster
  }
)(ContactCardMenu);

ContactCardMenu.propTypes = {
  contact: PropType.object.isRequired,
  openModal: PropType.func.isRequired,
  displayHorizontal: PropType.bool,
  isSuperAdmin: PropType.bool,
  canDelete: PropType.bool,
  searchValue: PropType.string,
  getMembers: PropType.func.isRequired
};
