import { useEffect, useState, useRef, ChangeEvent, DragEvent, FC, useMemo } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import type { Image } from './ImageUploadGallery.types';
import ImageWrapper from './ImageWrapper';
import autoAnimate from '@formkit/auto-animate';

import './styles.css';
import { SettingsBackupRestore } from '@mui/icons-material';

interface ImageUploadGalleryProps {
  className?: string;
  onChange: (images: Image[]) => void;
  fileSizeError?: string;
  fileTypeError?: string;
  imageExtension?: string[];
  maxFileSize?: number;
}

const ImageUploadGallery: FC<ImageUploadGalleryProps> = ({
  className,
  onChange,
  fileSizeError = 'file size is too big!',
  fileTypeError = ' extension is not supported!',
  imageExtension = ['.jpg', '.jpeg', '.png', '.gif'],
  maxFileSize = 5242880
}) => {
  const inputRef = useRef(null);

  const [images, setImages] = useState<Image[]>([]);
  const [error, setError] = useState<string | null>(null);

  function handleChange(e: ChangeEvent) {
    const inputImages = (e.currentTarget as HTMLInputElement).files;
    const newImages: Image[] = [];
    if (error !== null) {
      setError(null); // Clear error when uploading new
    }

    Array.from(inputImages ?? []).forEach((image: File, index: number) => {
      const fileExtension = image.name.split('.').pop()?.toLowerCase();
      const isValidExtension = imageExtension.includes(`.${fileExtension}`);
      const isValidSize = image.size <= maxFileSize;
      const shortImageName = image.name?.slice(0, 25);

      if (!isValidExtension) {
        setError(`${shortImageName} ${fileTypeError}`);
        return;
      }

      if (!isValidSize) {
        setError(`${shortImageName} ${fileSizeError}`);
        return;
      }

      const order = images.length + 1 + index;

      newImages.push({
        id: `${image.name}-${order}`,
        order,
        file: image
      });
    });

    if (newImages.length > 0) {
      onChange([...images, ...newImages]);
      setImages((prev) => [...prev, ...newImages]);
    }
  }

  function clearImages() {
    setImages([]);
    setError(null);
  }

  const [dragId, setDragId] = useState('');

  function handleDrag(e: DragEvent<HTMLElement>) {
    setDragId(e.currentTarget.id);
  }

  function handleDrop(e: DragEvent<HTMLElement>) {
    e.preventDefault();

    const dragImage = images.find((image) => image.id === dragId);
    const dropImage = images.find((image) => image.id === e.currentTarget.id);

    const dragImageOrder = dragImage!.order;
    const dropImageOrder = dropImage!.order;

    const update = images.map((image) => {
      if (image.id === dragId) {
        image.order = dropImageOrder;
      }
      if (image.id === e.currentTarget.id) {
        image.order = dragImageOrder;
      }
      return image;
    });
    setImages(update);
  }

  function removeImage(id: string) {
    const update = images
      .filter((image) => image.id !== id)
      // Update the order after filtering
      .map((image, index) => ({
        id: image.id,
        order: index + 1,
        file: image.file
      }));
    setImages(update);
  }

  const parentRef = useRef(null);

  useEffect(() => {
    if (parentRef.current) {
      autoAnimate(parentRef.current);
    }
  }, [images]);

  const sizeInMb = useMemo(() => Math.floor(maxFileSize / 1048576), [maxFileSize]);
  const formatExtensions = imageExtension.join('|').replaceAll('.', '');

  return (
    <div className={['container', className].join(' ')}>
      <div className="controls">
        <label htmlFor="id_image_upload">
          Choose images
          <UploadIcon />
        </label>
        <input
          id="id_image_upload"
          type="file"
          accept="image/*"
          multiple
          ref={inputRef}
          onChange={handleChange}
        />
        <button className="clear-button" onClick={clearImages}>
          <SettingsBackupRestore
            color={images.length > 0 ? 'primary' : 'disabled'}
            fontSize={'large'}
          />
        </button>
      </div>
      <span>
        Max file size: {sizeInMb}mb, accepted: {formatExtensions}
      </span>
      {error && <div className="error-message">{error}</div>}
      <div className="preview-container" ref={parentRef}>
        {images.length > 0 ? (
          images
            .sort((a, b) => a.order - b.order)
            .map((image) => (
              <ImageWrapper
                key={image.id}
                image={image}
                handleDrop={handleDrop}
                handleDrag={handleDrag}
                handleRemove={removeImage}
              />
            ))
        ) : (
          <p>No images to display.</p>
        )}
      </div>
    </div>
  );
};

export default ImageUploadGallery;
