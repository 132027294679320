import CardContent from '@mui/material/CardContent';
import { AvatarWrapper } from '../../components/Avatar/AvatarWrapper';
import { Member } from '../../gql/generated';

type IDataCardItem = Member & {
  label: string;
  location: string;
  state: string;
  value?: string;
  valueSecondary?: string;
  gender?: 'Male' | 'Female';
  muktType?: string;
};
interface IDataCardProps {
  headerTitle: string;
  items: IDataCardItem[];
}

export const DataCard = (props: IDataCardProps) => {
  const { headerTitle, items = [] } = props;

  const renderItems = () => {
    let list = [];

    if (items && items instanceof Array && items.length) {
      list = items.map((obj, index) => {
        const showMuktype = typeof obj.muktType === 'string';
        return (
          <li key={index}>
            <div className="item-label">
              <div style={{ display: 'flex', gap: 8 }} className="item-name">
                <AvatarWrapper size={'48'} contact={obj} />
                <div>
                  <div>
                    {obj.label}
                    {showMuktype ? <span> ({obj.muktType})</span> : null}
                  </div>
                  <div className="item-location-container">
                    {obj.location && typeof obj.location === 'string' && obj.location.length ? (
                      <span className="item-location">{obj.location}</span>
                    ) : null}
                    {obj.state && <span className="item-state">{obj.state}</span>}
                  </div>
                </div>
              </div>
            </div>
            <div className="item-value">
              <div className="item-value">{obj.value}</div>
              <div className="item-value-secondary">{obj.valueSecondary}</div>
            </div>
          </li>
        );
      });
    } else {
      return <p>No Data</p>;
    }

    return <ul>{list}</ul>;
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-header-title">{headerTitle}</div>
      </div>
      <CardContent className="card-content-container">{renderItems()}</CardContent>
    </div>
  );
};
